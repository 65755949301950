<template>
  <div>
    <van-nav-bar
        :title="title"
        left-text="返回"
        left-arrow
        @click-left="onClickLeft"
    >
      <template #right>
        <van-icon name="wap-home-o" size="18" @click="toHome"/>
      </template>
    </van-nav-bar>
    <div>
      <van-search
          v-model="searchParamValue"
          show-action
          label="商品"
          placeholder="请输入商品关键词"
          @search="onSearch"
          @clear="onSearchClear"
      >
        <template #action>
          <div @click="showPopup" style="color: #1989fa">分类</div>
        </template>
      </van-search>
    </div>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >

        <van-collapse v-model="activeNames">
          <div v-for="item in dataList" :key="item.productId" :name="item.productId">
            <van-collapse-item title="同类不同价格" :name="item.productId">
              <template #title>
                <van-card
                  :price="(Number(item.price) * 100 / 100).toFixed(2)"
                  :title="item.storeName"
                  :thumb="item.image"
                >
                  <template #desc>
                    <div >
                      <div style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis; width: 200px;">
                        {{item.sku}}
                      </div>
                    </div>
                  </template>
                </van-card>
              </template>
              <div v-for="subItem in item.items" :key="subItem.sku" :name="subItem.sku">
                <div role="button" tabindex="0"
                     class="van-cell van-cell--clickable van-cell--center van-cell--borderless van-contact-card van-contact-card--edit">
                  <van-icon name="cash-on-deliver" />
                  <div class="van-cell__value van-cell__value--alone van-contact-card__value">
                    <div>规格：{{ subItem.sku }}</div>
                    <div>价格：{{ subItem.price }}元</div>
                  </div>
                  <i class="van-badge__wrapper van-icon van-icon-arrow van-cell__right-icon" @click="onShowPriceDetail(item.productId, subItem.sku, item.storeName)"></i>
                </div>
              </div>
            </van-collapse-item>
          </div>
        </van-collapse>
      </van-list>
    </van-pull-refresh>
    <van-popup v-model="orderInfoPopupShow">
      <div v-for="item in orderInfos" :key="item.id" :name="item.id" style="width: 300px">
        <van-card
          :price="(Number(item.price) * 100 / 100).toFixed(2)"
          :desc="item.sku"
          :title="item.storeName"
          :thumb="item.image"
          :num="item.number"
        >
        </van-card>
      </div>
    </van-popup>

    <van-popup v-model="priceDetailPopupShow" position="bottom" :style="{ height: '30%',width: '100%'}">
      <van-notice-bar
        wrapable
        :scrollable="false"
        :text="popupShowProductName + '规格' + '【'+popupShowSku+'】' + '历史价格'"
      />
      <van-steps direction="vertical">
        <van-step v-for="item in priceDetails" :key="item.price" :name="item.price">
          <div>【{{popupShowSku}}】{{item.price}}元 {{item.createTime}}</div>
        </van-step>
      </van-steps>
    </van-popup>

    <itemSelectPopup ref="itemSelectPopup"></itemSelectPopup>
  </div>
</template>

<script>
import {
  Card,
  Collapse,
  CollapseItem,
  ContactCard,
  List,
  NavBar,
  NoticeBar,
  PullRefresh,
  Search,
  ShareSheet,
  Step,
  Steps
} from 'vant';
import {mapState} from "vuex";
import itemSelectPopup from "@/components/itemSelectPopup";
import * as purchaseService from "@/api/admin/purchase";

export default {
  components: {
    [Search.name]: Search,
    [NavBar.name]: NavBar,
    [ShareSheet.name]: ShareSheet,
    itemSelectPopup: itemSelectPopup,
    [PullRefresh.name]: PullRefresh,
    [List.name]: List,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Card.name]: Card,
    [NoticeBar.name]: NoticeBar,
    [Step.name]: Step,
    [Steps.name]: Steps,
    [ContactCard.name]: ContactCard,
  },

  data() {
    return {
      options: [],
      title: '商品进货价格',
      searchParamValue: '',
      searchParam: {},
      activeNames: [],
      orderInfoPopupShow: false,
      priceDetailPopupShow: false,
      dataList: [],
      loading: false,
      finished: false,
      refreshing: false,
      pages: {
        perPage: 8,
        currPage: 1,
        pageCount: 3
      },
      orderInfos: [],
      priceDetails: [],
      popupShowSku: null,
      popupShowProductName: null,
    };
  },
  watch: {
    cate: {
      handler(newVal) {
        if (newVal.cateId === undefined) {
          return
        }
        this.onSelectCate(newVal.cateId)
      },
      immediate: true,
    }
  },
  computed: {
    ...mapState({
      cate: state => state.order.cate,
    }),
  },
  created() {
    if (this.$route.query.factoryUuid) {
      this.searchParam.factoryUuid = this.$route.query.factoryUuid
      this.searchParamValue = '查找' + this.$route.query.factoryUuid + '厂家的价格'
    }
    this.searchParam.sortType = 1;
  },
  methods: {
    toHome() {
      this.$router.push({path: '/index'})
    },
    onSelectCate(cateId) {
      this.searchParamValue = '条件筛选'
      this.searchParam.cateId = cateId
      this.onRefresh();
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    onSearchClear() {
      this.searchParam.storeName = null
      this.searchParam.cateId = null
      this.searchParam.factoryUuid = null
      this.searchParamValue = null
      this.onRefresh();
    },
    onSearch() {
      this.searchParam.storeName = this.searchParamValue;
      this.onRefresh();
    },
    showPopup() {
      this.$refs.itemSelectPopup.isPopupShow = true
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.dataList = [];
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.pages.currPage = 1;
      this.onLoad();
    },
    resetData() {
      this.pages = {
        perPage: 8,
        currPage: 1,
        pageCount: 3
      };
      this.loading = false;
      this.finished = false;
      this.refreshing = false;
    },
    onLoad() {
      if (this.refreshing) {
        this.dataList = [];
        this.refreshing = false;
      }
      this.searchParam.page = this.pages.currPage
      this.searchParam.limit = 10
      console.log('onLoad', this.searchParam)
      let that = this;
      purchaseService.pagePrice(this.searchParam).then(res => {
        if (res.status === 200) {
          that.dataList = that.dataList.concat(res.data.records)
          this.loading = false;
          that.pages.currPage++;
          that.pages.pageCount = res.data.pages;
          if (that.pages.currPage > that.pages.pageCount) {
            that.finished = true;
          }
        }
      });
    },
    onShowPriceDetail(productId, sku, productName) {
      this.popupShowSku = sku
      this.priceDetailPopupShow = true
      this.popupShowProductName = productName
      let param = {
        factoryUuid: this.searchParam.factoryUuid,
        productId: productId,
        sku: sku
      }
      purchaseService.listPriceDetail(param).then(res => {
        if (res.status === 200) {
          this.priceDetails = res.data
        }
      })
    },
  }
};
</script>

<style lang="less">
.van-contact-card1 {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 16px;
  overflow: hidden;
  color: #323233;
  font-size: 14px;
  line-height: 24px;
  background-color: #fff;
}

.van-contact-card1::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2px;
  background: -webkit-repeating-linear-gradient(135deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background-size: 80px;
  content: '';
}

.van-contact-card2::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2px;
  background: -webkit-repeating-linear-gradient(135deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background-size: 80px;
  content: '';
}

.custom-title {
  margin-right: 4px;
  vertical-align: middle;
  display: flex;
  margin-left: 3px;
}

.search-icon {
  font-size: 16px;
  line-height: inherit;
}

.user {
  &-poster {
    width: 100%;
    height: 53vw;
    display: block;
  }

  &-group {
    margin-bottom: 15px;
  }

  &-links {
    padding: 15px 0;
    font-size: 12px;
    text-align: center;
    background-color: #fff;

    .van-icon {
      display: block;
      font-size: 24px;
    }
  }
}


.address-popup-load {
  text-align: center;
  margin: 0 auto;
  padding: 0;
}

.card-goods {
  padding: 10px 0;
  background-color: #fff;

  &__item {
    position: relative;
    background-color: #fafafa;

    .van-checkbox__label {
      width: 100%;
      height: auto; // temp
      padding: 0 10px 0 15px;
      box-sizing: border-box;
    }

    .van-checkbox__icon {
      top: 50%;
      left: 10px;
      z-index: 1;
      position: absolute;
      margin-top: -10px;
    }

    .van-card__price {
      color: #f44;
    }
  }
}

.va-tag-su {
  margin-left: 2px
}
</style>
